import React from "react"
import "../css/ImgInvitatie.css"
import background from "../images/pampas4.jpg"



const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center  p-5" style={{ backgroundImage: `url(${background})` }}>
            <h1 className="pt-3" style={{color: 'white', fontSize: '45px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>Accommodation</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text">
<h5>
We narrowed down the search to a few hotel options. <br/>
If you want to stay here, let us know and we will help reserve a room for you. <br/>
All are in the city center and pretty close to each other. <br/>

<br/> <br/>
Hotel Plai <br/>
<a href="https://plai.md/en/"> https://plai.md/en/</a> <br/> <br/>

Thomas Albert Hotel <br/>
<a href="http://thomasalbert.md/"> http://thomasalbert.md/</a> <br/> <br/>

City Park Hotel <br/>
<a href="http://www.citypark.md/?lang=en"> http://www.citypark.md/?lang=en</a>
<br/><br/>
If you are looking for a short-term rental, 
check out Airbnb in our recommended search radius

<a href="https://www.airbnb.com/s/Chisinau--Moldova/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&query=Chi%C8%99in%C4%83u%2C%20Moldova&place_id=ChIJoWm3KDZ8yUARPN1JVzDW0Tc&date_picker_type=calendar&checkin=2022-09-15&checkout=2022-09-18&adults=2&source=structured_search_input_header&search_type=user_map_move&ne_lat=47.03445513802323&ne_lng=28.848617037909065&sw_lat=47.02577323474375&sw_lng=28.832498034351232&zoom=15&search_by_map=true&room_types%5B%5D=Entire%20home%2Fapt"> Link to Airbnb search radius</a>
</h5>
    



                        </div>










                       {/** <h4 class="card-footer">We would very much like you to come!</h4>

                        <a href="#forms" className="btn btn-primary">Confirm your presence</a> */}

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;