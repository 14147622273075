import React from "react"
import background from "../images/pampas4.jpg"
import Accordion from 'react-bootstrap/Accordion'


const FAQ = () => {
    return (

        <section id="faq" className="text-center  p-5" style={{ backgroundImage: `url(${background})` }}>
            <h1 className="pt-3" style={{color: 'white', fontSize: '60px', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>Q&A</h1>
<div >


            <Accordion defaultActiveKey="0" className="container" style={{ maxWidth: '800px' }}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Why in Moldova?</Accordion.Header>
                    <Accordion.Body>
                        Germany is what we now call home but Moldova is also a big part of our lives, so we want our wedding to represent that. Also, for guests from outside of Moldova it will be an interesting experience.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How to get there?</Accordion.Header>
                    <Accordion.Body>
                        Direct flights to Chisinau Airport (KIV) go from DUS, DTM, HAM, BER, FMM, FRA and MLH. Timings are very different depending on each departing airport.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Should I stay more days?</Accordion.Header>
                    <Accordion.Body>
                        If you can make it for the day of the party, that is already amazing. If given the flights that are available to you, you consider a trip for more than one day, that is even better. Just let us know what is possible for you. We will organize some activities and tours for all our guests who want to combine the party with a mini holiday. We will be there for a week, from Wednesday to Wednesday.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>How to get around?</Accordion.Header>
                    <Accordion.Body>
                        We will help you organize a pick up at the airport if you want. For taxi services, generally hotel receptions, restaurants, etc. can help you out with that. If you visit from Solingen or Valparaiso, we would also suggest taking a “Troleibuz” /troh-ley-boose/  to feel a little like home.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Cash or card?</Accordion.Header>
                    <Accordion.Body>
                        The local currency is LEU. 1€ equals approx. 20 lei. Exchange points are on every corner. You can also pay by card in most restaurants, bars, shops; although not in Taxis and small supermarkets.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>What to expect of the party?</Accordion.Header>
                    <Accordion.Body>
                        Dress as you would normally do for a wedding. Dancing will start already in the afternoon. Speeches will be held. Food will be plenty. Drinks will be mostly regional. The Party will probably slow down around midnight. But do not worry, we usually all have our share of dining, dancing and drinking until then.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>What to bring as a gift?</Accordion.Header>
                    <Accordion.Body>
                        You considering coming all this way to celebrate with us, already means a lot. This can be the best present. As we will travel by plane, we won't be able to transport anything back anyway.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Who can I contact other than you?</Accordion.Header>
                    <Accordion.Body>
                        For matters that you don't want to discuss with us directly, you can contact Kiki, Vica, Christian or Niko in confidence. They will be able to help you out.
                        <br />Kiki +44 7773 045213 <br />
                        Vica +49 1515 1161800 <br />
                        Christian +49 170 4817927 <br />
                        Niko +49 151 27536599
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>When do I need to reply?</Accordion.Header>
                    <Accordion.Body>
                    We know you might need to move some things around to find time in your schedule. We understand that it can take time. However, for our further planning (accomodation, activities) it would be good for us to know by <b>July 15</b> .
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
</div>
        </section>

    )
}

export default FAQ;