import restaurant from "./images/restaurant.jpg";
import img_card from "./images/noi.jpg";
import imgOmSuflet from "./images/pampas1.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/pampas3.jpeg";
import imgheaderm from "./images/pampas3.jpeg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*anusic007@gmail.com*/}
{/** message1: "P.S. Vă vom fi recunoscători dacă tradiţionalul buchet de flori va fi înlocuit cu flori în ghiveci!",*/} 
const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Romina",
        mireasa: "Felix",
        data: "September 17th 2022",
        data_confirmare: "July 15",
        savedata: "",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "felix.uftring@gmail.com", 
       tel: "+49 172 78 48 997",
       phone: "tel:+373xxx",
       viber: "viber://chat?number=%2B373xxx",
       whatsapp: "https://wa.me/+491727848997",
       messenger: "https://www.messenger.com/t/viteoc.ivascu",
       tel1: "+49 176 55 79 6044",
       phone1: "tel:+4917655796044",
       viber1: "viber://chat?number=%2B4917655796044",
       whatsapp1: "https://wa.me/+4917655796044",
       messenger1: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Restaurant",
            name: "Cristal Banquet Hall",
            data: "the 17th September 2022,",
            ziua: "Saturday,",
            ora: "14:00",
            adress: "Str. Albişoara 84/6, Chișinău, Moldova",
            harta: "https://goo.gl/maps/ZYFoyqJYbSfYV2FG9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10876.66658037286!2d28.8270722!3d47.0369607!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1769c1f7c017bd32!2sCristal!5e0!3m2!1sen!2s!4v1655374622505!5m2!1sen!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "We are getting married and we would love to celebrate with you! We hope you can join us. ",
       
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "We would be honoured and happy to share those moments with you and sincerely hope that you can join us at the banquet.",
            message: "P.S: We would be grateful if you could confirm your presence!",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Where there is great love, there are always miracles.\"",
            message: "Willa Cather",
        }
    ],

}

export default data;