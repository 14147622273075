import { WhatsApp } from "@mui/icons-material";
import React from "react";

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import Form from "./components/Form";
import FormValidation from "./components/FormValidations";

import data from "./Data"


const FormSection = (props) => {

  return (
    <>
      <section id="forms" className="form" >
        <h2 className="text-center mt-5" style={{ fontFamily: 'Dancing Script', fontSize: '40px' }}>Confirmation</h2>


        {data.introData.map((item) => {
          return (


            <div class="card text-center container" style={{ maxWidth: "55rem" }}>
              <div class="card-header" >
                Please confirm by <b>{item.data_confirmare}</b>  if you will be able to attend
              </div>


              <FormValidation />

              <div class="card-footer text-muted">
                Alternatively you can reach us at the below numbers:<br />
                {item.mire} {item.tel1} : <br /><a class=" m-2 btn_logo" href={item.phone1}> <PhoneIphoneIcon /> Phone</a>
                {/**  <a className="m-2 btn_logo" href={item.viber1}> <PhoneIcon />Viber</a>*/}
                <a className="m-2  btn_logo" href={item.whatsapp1}><WhatsApp />WhatsApp</a> <br/>
                {/**<a className=" m-2  btn_logo" href={item.messenger1}><MessageIcon />Messenger</a><br />*/}
                {item.mireasa}  {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
                <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>

              </div>
            </div>)
        })}


      </section>
    </>
  )


}

export default FormSection;